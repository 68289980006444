let frontendDomain = 'demoleg.wemake.pt';
let backendDomain  = 'demoleg-back.wemake.pt';

let splitFrontendDomain              = frontendDomain.split('.');
let splitFrontendHostname            = window.location.hostname.split('.');
let subdomain                        = splitFrontendHostname.length === (splitFrontendDomain.length + 1) ? (splitFrontendHostname[0] + '.') : '';
let subdomainsWithUnsupportedSockets = [];

export default {
    debug: false,

    testing: false,

    backendBaseUrl: 'https://' + subdomain + backendDomain,

    translations: {
        default:  'pt',
        fallback: 'en',
        endpoint: 'https://' + subdomain + backendDomain + '/api/translations/from-locale/{{lng}}',
    },

    webSockets: {
        supported:   subdomainsWithUnsupportedSockets.indexOf(splitFrontendHostname[0]) === -1,
        broadcaster: {
            name:      'socket.io',
            endpoint:  'https://' + subdomain + backendDomain + ':6047',
            apiKey:    'nrhmk8ic64lgnqbs6t794gpj6per33ho5q34j2mrgia61j8hf238rf0c014b',
            namespace: 'WeMake.Legislation.Events',
        },
    },

    uploadMaxFilesize: 512 * 1024, // in MB

    productName: 'WeMake Legislation',

    clientName: 'WeMake',

    copyApplicabilityNotesToEvidences: false,
};
