import { inject }               from 'aurelia-framework';
import { AppContainer }         from 'resources/services/app-container';
import { BaseViewModel }        from 'base-view-model';
import { DiscardChangesDialog } from 'resources/elements/html-elements/dialogs/discard-changes-dialog';

@inject(AppContainer)
export class BaseFormViewModel extends BaseViewModel {

    /**
     * Constructor
     *
     * @param appContainer
     */
    constructor(appContainer) {
        super(appContainer);
    }

    /**
     * Handles canDeactivate event
     */
    canDeactivate() {
        let canDeactivate = true;

        if (this.discardable === true) {
            return canDeactivate;
        }

        if (this.initialModel && this.model) {
            canDeactivate = this.model.equals(this.initialModel);
        }

        if (!canDeactivate) {
            return this.appContainer.dialogService
                .open({viewModel: DiscardChangesDialog})
                .whenClosed(response => !response.wasCancelled);
        }

        return canDeactivate;
    }

    /**
     * Handles deactivate event
     */
    deactivate() {
        //
    }

    /**
     * Handles default submit action
     * Chooses between submitCreate and submitUpdate based if the field id on the model is defined
     */
    submit(redirectToList = true) {
        this.alert = null;

        return (typeof this.model.id === 'undefined' || this.model.id === null)
            ? this.submitCreate(redirectToList)
            : this.submitUpdate();
    }

    /**
     * Handles default submit action for create
     *
     * If route is not specified it assumes the route conventions
     */
    submitCreate(redirectToList = true) {
        let redirectRoute = redirectToList
            ? this.navigationInstruction.name.replace('create', 'index')
            : {name: this.navigationInstruction.name.replace('create', 'edit')};

        // calls repository create method
        this.repository.create(this.model)
            .then((response) => this.defaultFormSubmittedCallback(response, redirectRoute))
            .catch((error) => console.log(error));
    }

    /**
     * Handles default submit action for update
     */
    submitUpdate() {
        let redirectRoute = this.navigationInstruction.name.replace('edit', 'index');

        // calls usersRepository create method
        this.repository.update(this.model.id, this.model)
            .then((response) => this.defaultFormSubmittedCallback(response, redirectRoute))
            .catch((error) => console.log(error));
    }

    /**
     * Default form submitted callback
     *
     * @param response
     * @param redirectRoute
     */
    defaultFormSubmittedCallback(response, redirectRoute) {
        if (response.status === true) {
            if (isObject(redirectRoute)) {
                redirectRoute.params = {id: response.model.id};
            }

            this.appContainer.notifier.successNotice(response.message);
            this.initialModel.assign(this.model);
            this.redirectToRoute(redirectRoute);
        } else {
            this.alert = this.alertMessage(response.status, response.message, response.errors);
        }
    }

    /**
     * Resets form fields
     */
    resetForm(nullifyAlert = true) {
        this.resetModelValues()
            .then(() => {
                // publishes `form-reseted` event
                this.appContainer.eventAggregator.publish('form-reseted', this.formId);

                if (nullifyAlert) {
                    this.alert = null;
                }
            });
    }

    /**
     * Resets model to initial values
     *
     * @returns {Promise}
     */
    resetModelValues() {
        return new Promise((resolve, reject) => {
                this.model.assign(this.initialModel);
                resolve(true);
                reject(new Error('Error'));
            },
        );
    }

    /**
     * Resets model to initial values
     *
     * @returns {Promise}
     */
    updateInitialModelValues() {
        return new Promise((resolve, reject) => {
                this.initialModel.assign(this.model);
                resolve(true);
                reject(new Error('Error'));
            },
        );
    }

}
