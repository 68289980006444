import { inject }        from 'aurelia-framework';
import { AppContainer }  from 'resources/services/app-container';
import { BaseViewModel } from 'base-view-model';

@inject(AppContainer)
export class BaseListViewModel extends BaseViewModel {

    filterFormIsDirty = false;

    /**
     * Constructor
     *
     * @param appContainer
     */
    constructor(appContainer) {
        super(appContainer);
    }

}
