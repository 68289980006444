import { Aurelia, inject }  from 'aurelia-framework';
import { AppContainer }     from 'resources/services/app-container';
import { CustomHttpClient } from 'resources/services/custom-http-client';
import { LocalStorage }     from 'resources/services/local-storage';

@inject(Aurelia, AppContainer, CustomHttpClient, LocalStorage)
export class AutoLogin {

    /**
     * Constructor
     *
     * @param aurelia
     * @param appContainer
     * @param httpClient
     * @param localStorage
     */
    constructor(aurelia, appContainer, httpClient, localStorage) {
        this.aurelia      = aurelia;
        this.appContainer = appContainer;
        this.httpClient   = httpClient;
        this.localStorage = localStorage;
    }

    /**
     * Handles activate event
     *
     * @param params
     */
    activate(params) {
        this.httpClient
            .post('auth/auto-login/' + params.token + '?signature=' + params.signature)
            .then((response) => {
                if (response.token) {
                    // set token
                    this.localStorage.set('aurelia_authentication', JSON.stringify(response));

                    this.aurelia.setRoot('app').then(() => {
                        // route to default route
                        this.appContainer.router.navigateToRoute('dashboard');

                        //this.localStorage.set('auto-login', JSON.stringify(response)); // To be used when session is lost
                    });
                } else {
                    // show some kind of notification!
                    this.appContainer.notifier.dangerNotice(response.message);

                    // remove autologin token to prevent infinite loops
                    this.localStorage.remove('auto-login');

                    // redirect to normal login
                    this.appContainer.router.navigateToRoute('login');
                }
            });
    }

}
